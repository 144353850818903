import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from '@sentry/react';

import App from "./App";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
