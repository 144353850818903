import { Splash } from "./components/Splash";

import "./styles/global.scss"
import "./styles/reset.scss";

function App() {
  return <Splash />;
}

export default App;
