import { Logo } from "@/assets/Logo";

import styles from "./styles.module.scss";

export const Splash = () => {
  return (
    <main className={styles.container}>
      <Logo size={500} />
    </main>
  );
};
