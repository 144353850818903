export const Logo = ({ size = 177, color = '#fff' }: SvgProps) => {
  return (
    <svg
      width={size}
      height={size / 5.53}
      viewBox="0 0 177 32"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.5" d="M30.5177 22.2458L18.3997 10.4703L0 10.4497L12.1377 22.2458H30.5177Z" />
      <path d="M26.7655 4.69179C20.4105 -1.56393 10.1057 -1.56393 3.75078 4.69179C2.0371 6.37856 0.789522 8.34979 0 10.4497H30.5162C29.7267 8.34979 28.4791 6.37856 26.7655 4.69179Z" />
      <path d="M0 22.2456C0.790985 24.2005 2.0349 26.0352 3.74127 27.6067C10.1021 33.4642 20.4149 33.4642 26.7757 27.6067C28.4821 26.0352 29.7267 24.2005 30.517 22.2456H0Z" />
      <path d="M46.9434 19.2214C46.9434 18.6788 47.3041 18.4555 47.7314 18.4555H48.4536C48.9131 18.4555 49.077 18.8068 49.0448 19.4126C48.979 20.5611 49.2417 21.8376 50.621 21.8376C52.0002 21.8376 52.4268 20.8164 52.4268 19.6039C52.4268 18.2315 51.1463 17.2743 50.0956 16.5404C48.848 15.6466 47.0751 14.1155 47.0751 12.1052C47.0751 9.55226 48.3885 8.02051 50.7856 8.02051C53.1827 8.02051 54.3644 9.07368 54.3644 12.2005C54.3644 12.7751 53.9708 12.9663 53.5764 12.9663H52.8542C52.3946 12.9663 52.23 12.6151 52.2629 12.0092C52.3288 11.02 52.1971 9.90284 50.7856 9.90284C49.669 9.90284 49.0785 10.6687 49.0785 11.8172C49.0785 13.0936 49.9324 13.9555 50.8185 14.5934C52.1978 15.5826 54.4303 17.2096 54.4303 19.4119C54.4303 21.4223 53.741 23.7199 50.6217 23.7199C48.2575 23.7199 46.9441 22.3794 46.9441 19.2206L46.9434 19.2214Z" />
      <path d="M56.4971 15.871C56.4971 11.2125 57.2193 8.021 60.6013 8.021C63.0964 8.021 64.213 9.42475 64.213 13.4774C64.213 14.1153 64.1142 15.2645 63.9833 16.0943H58.7295C58.7295 20.115 59.0581 21.8381 60.6335 21.8381C62.2089 21.8381 62.2425 20.2423 62.2096 19.5724C62.1767 18.9658 62.3406 18.6153 62.8008 18.6153H63.3591C63.7528 18.6153 64.1472 18.8066 64.1472 19.3811C64.1472 22.2526 63.0635 23.7211 60.5683 23.7211C57.1863 23.7211 56.4971 20.5303 56.4971 15.871ZM62.1445 14.4033C62.1774 14.0207 62.2103 13.5734 62.2103 12.8715C62.2103 11.1165 61.9806 9.90404 60.6013 9.90404C59.222 9.90404 58.7625 11.1485 58.7625 14.4033H62.1445Z" />
      <path d="M74.4092 8.12553C73.8553 8.12553 73.2179 8.44482 72.7562 9.05639C72.5755 8.87007 72.1972 8.59345 71.925 8.45976C71.6023 8.30331 71.1187 8.021 70.1506 8.021C67.8918 8.021 66.4079 9.68288 66.4079 13.2577C66.4079 15.5155 67.021 17.0202 68.0213 17.8046C67.3759 18.3066 67.021 18.9964 67.021 19.9685C67.021 20.878 67.3437 21.5052 67.8925 22.0379C66.6983 22.8528 66.0537 24.2957 66.0537 25.8324C66.0537 28.2154 67.5706 30.2534 70.4096 30.2534C73.2487 30.2534 74.7655 28.2154 74.7655 25.8324C74.7655 23.4494 73.2494 21.3168 70.4096 21.3168C70.1835 21.3168 69.8286 21.3481 69.6355 21.3794C69.2484 21.1284 69.0223 20.6896 69.0223 19.9685C69.0223 19.1849 69.3772 18.6828 69.8286 18.4631C69.9252 18.4631 70.0547 18.4944 70.1513 18.4944C72.4101 18.4944 73.894 16.8325 73.894 13.2577C73.894 12.0395 73.7236 11.0575 73.4155 10.2838C73.5962 10.2546 73.7777 10.2269 73.9584 10.2269C74.2167 10.2269 74.3777 10.2582 74.6682 10.4153C74.8292 10.5092 74.9265 10.5405 75.0875 10.5405C75.4102 10.5405 75.5711 10.352 75.5711 10.0384V9.09763C75.5711 8.43914 75.0875 8.12553 74.4099 8.12553H74.4092ZM70.4082 23.2603C72.0216 23.2603 72.7635 24.3888 72.7635 25.8317C72.7635 27.2746 72.0216 28.4031 70.4082 28.4031C68.7947 28.4031 68.0528 27.2746 68.0528 25.8317C68.0528 24.3888 68.7947 23.2603 70.4082 23.2603ZM70.1499 16.8318C68.7625 16.8318 68.4076 15.295 68.4076 13.257C68.4076 11.2189 68.7625 9.68217 70.1499 9.68217C71.5372 9.68217 71.8921 11.2189 71.8921 13.257C71.8921 15.295 71.4728 16.8318 70.1499 16.8318Z" />
      <path d="M76.7566 20.2424V8.81899C76.7566 8.46841 77.0193 8.21311 77.38 8.21311H78.3649C78.7264 8.21311 78.9883 8.46841 78.9883 8.81899V20.2424C78.9883 21.1676 79.3498 21.7102 80.3676 21.7102C80.9588 21.7102 81.845 21.4869 82.535 21.1356V8.81828C82.535 8.4677 82.7976 8.2124 83.1591 8.2124H84.144C84.5047 8.2124 84.7674 8.4677 84.7674 8.81828V21.3269C84.7674 22.0607 85.0301 22.348 85.5884 22.348V22.7306C85.5884 23.1772 85.1289 23.7198 83.9794 23.7198C83.3552 23.7198 82.8628 23.4645 82.6659 22.7306C81.8779 23.3052 80.8271 23.7198 79.7764 23.7198C78.1015 23.7198 76.7559 22.8266 76.7559 20.2417L76.7566 20.2424Z" />
      <path d="M87.9854 22.9228V8.8185C87.9854 8.46792 88.248 8.21263 88.6088 8.21263H89.5937C89.9551 8.21263 90.2178 8.46792 90.2178 8.8185V9.23309C91.1369 8.40321 92.0888 8.21191 92.4832 8.21191C93.1725 8.21191 93.6649 8.53121 93.6649 9.20108V10.1582C93.6649 10.4775 93.501 10.6688 93.1725 10.6688C93.0415 10.6688 92.9098 10.6368 92.7459 10.5408C92.4503 10.3815 92.2864 10.3495 92.0237 10.3495C91.0388 10.3495 90.2178 10.9554 90.2178 10.9554V22.9214C90.2178 23.272 89.9551 23.5273 89.5937 23.5273H88.6088C88.248 23.5273 87.9854 23.272 87.9854 22.9214V22.9228Z" />
      <path d="M94.8477 15.871C94.8477 9.80804 96.2921 8.021 98.8531 8.021C101.414 8.021 102.859 9.80804 102.859 15.871C102.859 21.934 101.414 23.7211 98.8531 23.7211C96.2921 23.7211 94.8477 21.934 94.8477 15.871ZM100.626 15.871C100.626 10.2866 99.8709 9.93604 98.8531 9.93604C97.8353 9.93604 97.0801 10.2873 97.0801 15.871C97.0801 21.4548 97.8353 21.8061 98.8531 21.8061C99.8709 21.8061 100.626 21.4548 100.626 15.871Z" />
      <path d="M104.929 19.2214C104.929 18.6788 105.289 18.4555 105.717 18.4555H106.439C106.898 18.4555 107.062 18.8068 107.03 19.4126C106.964 20.5611 107.227 21.8376 108.606 21.8376C109.986 21.8376 110.412 20.8164 110.412 19.6039C110.412 18.2315 109.132 17.2743 108.081 16.5404C106.833 15.6466 105.06 14.1155 105.06 12.1052C105.06 9.55226 106.374 8.02051 108.771 8.02051C111.168 8.02051 112.35 9.07368 112.35 12.2005C112.35 12.7751 111.956 12.9663 111.562 12.9663H110.84C110.38 12.9663 110.215 12.6151 110.248 12.0092C110.314 11.02 110.182 9.90284 108.771 9.90284C107.654 9.90284 107.064 10.6687 107.064 11.8172C107.064 13.0936 107.918 13.9555 108.804 14.5934C110.183 15.5826 112.416 17.2096 112.416 19.4119C112.416 21.4223 111.726 23.7199 108.607 23.7199C106.243 23.7199 104.929 22.3794 104.929 19.2206L104.929 19.2214Z" />
      <path d="M121.148 23.6886C119.901 23.6886 119.113 23.178 118.817 22.5714V29.8149C118.817 30.1662 118.554 30.4208 118.193 30.4208H115.403C115.042 30.4208 114.779 30.1655 114.779 29.8149V8.81839C114.779 8.46781 115.042 8.21251 115.403 8.21251H116.552C117.472 8.21251 118.226 8.46781 118.588 9.32968C118.982 8.49981 119.999 8.05322 121.149 8.05322C123.94 8.05322 125.417 10.2228 125.417 15.8713C125.417 21.5197 123.94 23.6893 121.149 23.6893L121.148 23.6886ZM119.769 10.9567C119.375 10.9567 119.08 11.0527 118.817 11.18V20.5618C119.08 20.6891 119.375 20.7851 119.769 20.7851C120.721 20.7851 121.378 20.4025 121.378 15.8713C121.378 11.34 120.722 10.9574 119.769 10.9574V10.9567Z" />
      <path d="M134.119 12.2971C133.988 12.2971 133.823 12.2331 133.659 12.1378C133.463 12.0105 133.167 11.9465 132.904 11.9465C131.919 11.9465 131.131 12.5531 131.131 12.5531V22.9241C131.131 23.2747 130.869 23.53 130.508 23.53H127.717C127.356 23.53 127.094 23.2747 127.094 22.9241V8.81974C127.094 8.46916 127.356 8.21387 127.717 8.21387H128.9C129.819 8.21387 130.476 8.69245 130.706 9.45833C131.461 8.66045 132.413 8.21387 133.398 8.21387C134.088 8.21387 134.58 8.53316 134.58 9.20303V11.756C134.58 12.0752 134.449 12.2985 134.12 12.2985L134.119 12.2971Z" />
      <path d="M140.554 23.7206C137.567 23.7206 135.235 21.9336 135.235 15.8706C135.235 9.80756 137.567 8.02051 140.554 8.02051C143.542 8.02051 145.873 9.80756 145.873 15.8706C145.873 21.9336 143.575 23.7206 140.554 23.7206ZM140.554 10.9887C139.7 10.9887 139.274 11.3393 139.274 15.8706C139.274 20.4018 139.7 20.7524 140.554 20.7524C141.408 20.7524 141.835 20.4018 141.835 15.8706C141.835 11.3393 141.408 10.9887 140.554 10.9887Z" />
      <path d="M164.129 23.5294H161.338C160.977 23.5294 160.715 23.2741 160.715 22.9235V12.1059C160.715 11.3081 160.386 10.9895 159.697 10.9895C159.27 10.9895 158.679 11.1168 158.154 11.3721V22.9235C158.154 23.2741 157.891 23.5294 157.53 23.5294H154.739C154.378 23.5294 154.115 23.2741 154.115 22.9235V12.1059C154.115 11.3081 153.787 10.9895 153.097 10.9895C152.671 10.9895 152.08 11.1168 151.554 11.3721V22.9235C151.554 23.2741 151.292 23.5294 150.931 23.5294H148.14C147.779 23.5294 147.517 23.2741 147.517 22.9235V8.81914C147.517 8.46856 147.779 8.21326 148.14 8.21326H149.749C150.537 8.21326 151.226 8.56456 151.456 9.29843C152.08 8.69256 153.131 8.02197 154.641 8.02197C156.152 8.02197 157.071 8.56456 157.596 9.48972C158.516 8.65985 159.632 8.02197 161.241 8.02197C163.638 8.02197 164.754 9.42572 164.754 12.0106V22.9235C164.754 23.2741 164.491 23.5294 164.129 23.5294H164.129Z" />
      <path d="M171.681 23.7206C168.694 23.7206 166.362 21.9336 166.362 15.8706C166.362 9.80756 168.694 8.02051 171.681 8.02051C174.669 8.02051 177 9.80756 177 15.8706C177 21.9336 174.702 23.7206 171.681 23.7206ZM171.681 10.9887C170.827 10.9887 170.401 11.3393 170.401 15.8706C170.401 20.4018 170.827 20.7524 171.681 20.7524C172.535 20.7524 172.962 20.4018 172.962 15.8706C172.962 11.3393 172.535 10.9887 171.681 10.9887Z" />
    </svg>
  );
};
