// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

body, blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

body {
  margin: 0;
  scroll-behavior: smooth;
}
body a {
  text-decoration: inherit;
  color: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
}

ol, ul {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}`, "",{"version":3,"sources":["webpack://./src/styles/reset.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,SAAA;EAEA,uBAAA;AAAF;AAEE;EACE,wBAAA;EACA,cAAA;AAAJ;;AAIA;EACE,eAAA;EACA,YAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;AADF;;AAIA;EACE,oBAAA;AADF","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\nbody, blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {\n  margin: 0;\n}\n\nbody {\n  margin: 0;\n\n  scroll-behavior: smooth;\n\n  a {\n    text-decoration: inherit;\n    color: inherit;\n  }\n}\n\nimg, video {\n  max-width: 100%;\n  height: auto;\n}\n\nimg, svg, video, canvas, audio, iframe, embed, object {\n  display: block;\n}\n\nol, ul {\n  margin: 0;\n  padding: 0;\n}\n\nbutton {\n  font-family: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
